<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="Daikin - Delivery Order List">
      <b-modal v-model="modalDeliver" title="Deliver Process">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to process this DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="updateDeliver"
              >Confirm</b-button
            >
          </div>
        </template>
      </b-modal>

      <b-modal v-model="modalBulk" title="Bulk Unscan and Delete DO Process">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to process bulk unscan and delete this DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="bulkUnscan"
              >Confirm</b-button
            >
          </div>
        </template>
      </b-modal>

      <b-col cols="12">
        <!--only show for mobile view, THIS IS FIRST PAGE MENU TO CHOOSE DATA BY STATUS-->
        <b-row class="hide-on-desktop" v-if="btnshow">
          <b-col cols="12">
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="success"
                @click="btnForMobile('New')"
              >
                New DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="success"
                @click="btnForMobile('Scanning in Progress')"
              >
                Scanning in Progress DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="warning"
                @click="btnForMobile('Edit Delivery')"
              >
                Edit DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="warning"
                @click="btnForMobile('Edit in Progress')"
              >
                Edit in Progress DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="danger"
                @click="btnForMobile('Cancel')"
              >
                Cancel DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="danger"
                @click="btnForMobile('Delivery Canceled')"
              >
                DO Canceled List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('Scanning Completed')"
              >
                Scanning Completed DO List
              </b-button>
            </b-row>
            <b-row>
              <b-button
                size="lg"
                class="w-100 d-flex justify-content-center align-items-center"
                style="margin-bottom:15px;"
                variant="primary"
                @click="btnForMobile('All')"
              >
                All DO List
              </b-button>
            </b-row>
          </b-col>
        </b-row>
        <!--only show for mobile view, THIS IS SECOND PAGE MENU TO SHOW DATA TABLE-->
        <b-row :class="btnBackMobile">
          <b-button size="md" @click="actBackMobile" variant="primary">
            Back
          </b-button>
        </b-row>
        <b-row class="hide-on-desktop" v-if="showSortBy">
          <b-col md="6" style="margin-bottom:10px; margin-top:10px">
            <b-form-group
              label="Sort By"
              label-for="sort-by-select"
              label-cols-md="0"
              label-align-sm="left"
              label-size="md"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sort-by-select"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template #first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>

                <b-form-select
                  v-model="sortDesc"
                  :disabled="!sortBy"
                  :options="directionOptions"
                  size="sm"
                  class="w-25"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <!--end only show for mobile view-->
        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <b-row>
          <b-col md="6" class="ml-md-auto hide-on-mobile">
            <strong>Filter by Status :</strong>
            <b-form-select
              @input="(value) => statusFiltering(value)"
              id="delivery-status"
              v-model="deliveryStatus"
              :options="listStatus"
              @click="filterDelivery"
            >
            </b-form-select>
          </b-col>
          <b-col md="6" :class="conditionView">
            <b-form-input
              
              v-model="search"
              type="search"
              placeholder="Search by DO"
              style="float: right; margin-top: 20px; margin-bottom: 30px;"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" :class="conditionView" class="mb-1">
            <b-button @click="doFiltering" variant="primary" style="margin-right: 10px;">search</b-button>
            <span style="color: red;"></span>
          </b-col>
          <b-col md="6" :class="conditionView">
            <b-row>
              <b-col md="10" cols="9">
                <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  placeholder="Search by Delivery Date"
                  v-model="delivDate"
                  style="float: right;  margin-bottom: 30px;"
                />
              </b-col>
              <b-col md="2" cols="3">
                <b-button variant="danger" @click="cleanQuery">X</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--END this is options for search data-->
        <b-row :class="conditionView">
          <b-table
            responsive
            small
            hover
            bordered
            show-empty
            :items="filterDelivery"
            :fields="fields"
            :busy.sync="tableLoading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :stacked="stackedStatus"
          >
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * 10 }}
            </template>

            <template v-slot:cell(deliveryDate)="row">
              {{ dates(row.item.deliveryDate) }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                :dropleft="showDropdown"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item
                  v-if="
                    row.item.status == 'New' ||
                      (row.item.status == null && permission.pick)
                  "
                >
                  <b-button size="sm" @click="toScanPage(row.item)" class="mr-1">
                    Select
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  v-else-if="
                    row.item.status == 'Scanning in Progress' && permission.pick
                  "
                >
                  <b-button
                    size="sm"
                    @click="toResumeScanPage(row.item)"
                    class="mr-1"
                  >
                    Resume Scan
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item v-if="checkDetail(row.item)">
                  <b-button
                    size="sm"
                    variant="info"
                    @click="toDetailPage(row.item)"
                    class="mr-1"
                  >
                    Detail
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkUnpick(row.item) && permission.unpick"
                >
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="toUnpickScan(row.item)"
                    class="mr-1"
                  >
                    Unpick and Scan
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(progress)="row">
              <b-progress :value="progressValue(row.item.status)" :max="100" animated></b-progress>
            </template>
          </b-table>
        </b-row>
        <b-row :class="conditionView" style="margin-top:10px">
          <b-col md="9" />
          <b-col md="3">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              align="fill"
              class="hide-on-desktop"
            />
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              class="hide-on-mobile"
              style="float: right"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat, sleep } from "@/utils/utils";
export default {
  data() {
    let mobileViewQuery = window.matchMedia("(max-width: 761px)"); //check true or false width screen mobile view
    return {
      mobileViewQuery: mobileViewQuery, //all info matchMedia
      mobileView: mobileViewQuery.matches, // return true or false match screen moobile view
      //FIELD FOR MOBILE
      btnshow: true,
      showDropdown: true,
      btnBackMobile: "hide-at-all",
      conditionView: "ml-md-auto hide-on-mobile",
      stackedStatus: "md",
      //sort direction list for mobile view
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      //END FIELD FOR MOBILE
      fields: [
        { key: "index", label: "No" },
        { key: "sapSo", label: "SAP SO", filterable: true, sortable: true },
        { key: "sapDo", label: "SAP DO", filterable: true, sortable: true },
        {
          key: "deliveryDate",
          label: "Delivery Date",
          filterable: true,
          sortable: true,
        },
        { key: "status", label: "STATUS", filterable: true, sortable: true },
        // { key: "progress", label: "Progress", filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      shippingPoint:"1001",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      tableLoading: true,
      isLoading: false, //for loading
      sortBy: "", //this for mobile view
      sortDesc: false, //this for mobile view
      showSortBy: false, //this for mobile view
      search: "",
      selectData: "",
      modalDeliver: false,
      modalBulk: false,
      listStatus: [
        "New",
        "Edit Delivery",
        "Edit in Progress",
        "Cancel Delivery",
        "Delivery Canceled",
        "Scanning in Progress",
        "Scanning Completed",
        "Delivery in Progress",
        "Fulfilled",
        "Collected",
        "Returned",
        "Waiting Acknowledgment",
        "Waiting Approval",
        "Approved",
        "Return Completed",
        "Re-Delivery",
        "Deleted",
        "All",
      ],
      defaultStatus:"Scanning in Progress",
      deliveryStatus: "",

      delivDate: "", //model for search data by delivery date
    };
  },
  computed: {
    filterDelivery() {
      var result = this.deliveryOrders;

      if (this.deliveryStatus == "New") {
        result = result
          .filter((x) => x.status === "" || x.status === undefined)
          .map((el) => {
            return { ...el, status: "New" };
          });
      } else if (this.deliveryStatus == "Cancel Delivery") {
        result = result.filter(
          (x) =>
            x.status === "Cancel Delivery"
        );
        result = result;
      } else if (this.deliveryStatus =="Delivery Canceled"){
        result = result.filter(
          (x) =>
            x.status === "Delivery Canceled"
        );
        result = result;
      } else if (this.deliveryStatus == "Edit Delivery") {
        result = result.filter(
          (x) => x.status === "Edit Delivery" || x.mark === "Edit Delivery"
        );
      } else if (this.deliveryStatus == "Edit in Progress") {
        result = result.filter((x) => x.status === "Edit in Progress");
      } else if (this.deliveryStatus == "Deleted") {
        result = result.filter((x) => x.status === "Deleted");
      } else if (this.deliveryStatus == "All") {
        result.forEach((el) => {
          if (el.status == "New" || el.status == undefined || el.status =="") {
            el.status = "New";
          }
        });

      } else {
        result = result.filter((x) => x.status === this.deliveryStatus);
      }

      return result;
    },
    deliveryOrders() {
      var tempDO = this.$store.getters["delivery/getDO"].filter(
        (x) => x.shippingPoint === "1001"
      );
      return tempDO;
    },
    permission() {
      let result = userAccess("Delivery Orders Daikin", "delivery_order_menu");
      // console.log("Delivery Orders Sumitomo",result)
      return result;
    },
    // Create an options list from our fields
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  created() {
    document.title = "Delivery Order | RSP";

    
  },
  mounted() {
    // this.$store.dispatch("delivery/getDeliveryOrder");

    //temporary for detail dropdown button conditional by mobile view or desktop view
    this.mobileViewQuery.addListener(() => {
      this.mobileView = this.mobileViewQuery.matches;
    });

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageDeliveryOrdersDaikin")) {
      this.perPage = this.$session.get("perPageDeliveryOrdersDaikin");
    }
    if (this.$session.has("sortByDeliveryOrdersDaikin")) {
      this.sortBy = this.$session.get("sortByDeliveryOrdersDaikin");
    }
    if (this.$session.has("sortDescDeliveryOrdersDaikin")) {
      this.sortDesc = this.$session.get("sortDescDeliveryOrdersDaikin");
    }

    var isMobile = window.matchMedia("(max-width: 761px)").matches;
    console.log("mobile is ", isMobile);
    var queryAvailable = -1;
    var query;
    var doTemp;
    if (this.$route.query.status) {
      query = this.$route.query.status;
      queryAvailable = this.listStatus.findIndex((x) => {
        return x == query;
      });
    }
    if (this.$route.query.sapDo) {
      this.search = this.$route.query.sapDo;
      this.doFiltering()
    }
    console.log("queryavailable ", queryAvailable);
    if (queryAvailable >= 0) {
      if (isMobile) {
        this.btnForMobile(query);
        return;
      }
      this.deliveryStatus = query;
    } else {
      this.deliveryStatus = this.defaultStatus;
    }
    //this.queryDelivery()
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageDeliveryOrdersDaikin", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByDeliveryOrdersDaikin", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescDeliveryOrdersDaikin", sortDescNew);
    },
    // stackedStatus(stackedStatusNew) {
    //   this.$session.set("stackedStatusWarehouseDistribution", stackedStatusNew)
    // }
  },
  methods: {
    ...mapActions({
      testScanDO: "delivery/updateDO",
      testBulkUnscan: "delivery/bulkUnscan",
      getDeliveryOrder: "delivery/getDeliveryOrder",
    }),
    onPageChange(page) {
      this.currentPage = page;
      this.queryDelivery();
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    dates(date) {
      return dateFormat(date);
    },
    queryDelivery() {

      this.getDeliveryOrder({
        shippingPoint:this.shippingPoint,
        page: this.currentPage,
        entry: this.perPage,
        sapDo: this.search,
        status: (this.deliveryStatus =="All")?undefined:this.deliveryStatus,
        delivDate: this.delivDate,
      }).then((data) => {
        this.processMetadata(data.metadata)
        this.isLoading = false
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    cleanQuery() {
      this.delivDate = "";
      this.currentPage = 1;
      this.queryDelivery();
    },
    doFiltering() {
      this.isLoading = true
      //this.search = value;
      this.currentPage = 1;
      this.queryDelivery();
    },
    fireFiltering(value) {
      this.delivDate = value;
      //this is for datepicker load store data with query
      this.currentPage = 1;
      this.queryDelivery();
    },
    statusFiltering(value) {
      this.deliveryStatus = value;
      let status = ["Edit Delivery","Edit in Progress","Cancel Delivery","Delivery Canceled"]
     
      if(status.includes(value)){
        this.currentPage = 1;
        this.queryDelivery();
      }
    },
    //METHOD FOR MOBILE VIEW
    btnForMobile(filter) {
      this.deliveryStatus = filter;
      this.btnBackMobile = "hide-on-desktop";
      this.conditionView = "ml-md-auto show-on-desktop";
      this.btnshow = false;
      this.showDropdown = false;
      this.showSortBy = true;
    },
    actBackMobile() {
      this.btnBackMobile = "hide-at-all";
      this.conditionView = "ml-md-auto hide-on-mobile";
      this.btnshow = true;
      this.showDropdown = true;
      this.showSortBy = false;
    },
    //END METHOD FOR MOBILE VIEW
    toDeliver(data) {
      this.modalDeliver = true;
      this.selectData = data;
    },
    toBulkUnscan(data) {
      this.modalBulk = true;
      this.selectData = data;
    },
    checkDetail(row) {
      var result;
      console.info('detail status ',row.status)
      if (
        row.status != null &&
        row.status !== "New" &&
        row.status !== undefined &&
        row.status !== "Scanning in Progress" &&
        row.status !== "Edit Delivery" &&
        row.status !== "Edit in Progress"
      ) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    checkBulk(row) {
      var result = false;
      if (row.status === "Cancel Delivery") {
        result = true;
      }
      if (row.mark === "Cancel Delivery") {
        result = true;
      }
      return result;
    },
    checkUnpick(row) {
      return row.status == "Edit Delivery" || row.status == "Edit in Progress"
    },

    
    //temporary test edit and cancel do
    checkCancel(row) {
      var result;
      if (row.status == "Delete Delivery" || row.status == "Delivery Canceled") {
        //temporary for button test
        result = false;
      } else if (row.status == "Cancel Delivery") {
        //temporary for button test
        result = false;
      } else if (
        row.status === "Returned" ||
        row.status === "Waiting Approval" ||
        row.status === "Approved"
      ) {
        result = false;
      } else if (row.status === "Fulfilled" || row.status === "Collected") {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    checkEdit(row) {
      var result;
      if (row.status == "Delete Delivery" || row.status == "Delivery Canceled") {
        //temporary for button test
        result = false;
      }
      // else if(row.status=='Edit Delivery'){ //temporary for button test
      //   result = false;
      // }
      else if (
        row.status === "Returned" ||
        row.status === "Waiting Approval" ||
        row.status === "Approved"
      ) {
        result = false;
      } else if (row.status === "Fulfilled" || row.status === "Collected") {
        result = false;
      } else {
        result = true;
      }
      return result;
    },
    testCancelDO(data) {
      let idDO = data.sapDo;
      let sapSo = data.sapSo;
      this.callbackCancelDO({ sapSo: sapSo, sapDo: idDO })
        .then(() => {
          this.$bvToast.toast("Cancel Delivery", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.queryDelivery()
        })
        .catch((e) => {
          // console.log(e)
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    testEditDO(data) {
      //temporary for edit delivery test
      let idDO = data.sapDo;
      this.$router.push({
        name: "delivery-edit",
        params: { id: idDO, from: "do-daikin",status:this.deliveryStatus },
      });
      this.$bvToast.toast("Edit Delivery", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    //END temporary test edit and cancel do
    async toScanPage(data) {
      //data.status = "Scanning in Progress";
      let idDO = data._id;
      //var editStatus;
      try {
        /*
        editStatus = await this.testScanDO({
          idDelivery: data._id,
          deliveryData: data,
        });
        // If to capture canceled, status is not equal to Edit in Progress
        if (editStatus.data.status !== "Scanning in Progress") {
          this.$bvToast.toast(
            "Edit to Scanning Progress failed, please refresh display",
            {
              title: "DO status not synced",
              variant: "danger",
              solid: true,
            }
          );
          return;
        }

        this.$bvToast.toast("Scanning In Progress", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        */
        //await sleep(500);

        this.$router.push({
          name: "warehouse-display-delivery",
          params: { id: idDO, from: "do-daikin" },
        });
      } catch (err) {
        this.$bvToast.toast("Error updating DO, call administrator", {
          title: "Edit to Scanning Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
    },
    toResumeScanPage(data) {
      let idDO = data._id;
      this.$router.push({
        name: "warehouse-display-delivery",
        params: { id: idDO, from: "do-daikin",status:this.deliveryStatus },
      });

      this.$bvToast.toast("Resuming Scan", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    toDetailPage(data) {
      let idDO = data._id;
      this.$router.push({
        name: "warehouse-detail-delivery",
        params: { id: idDO, from: "do-daikin",status:this.deliveryStatus },
      });
    },
    async toUnpickScan(data) {
      this.isLoading = true
      if (data.status == "Edit Delivery") {
        //data.status = "Edit in Progress";
        var editStatus;
        try {
          editStatus = await this.testScanDO({
            idDelivery: data._id,
            deliveryData: {status:"Edit in Progress"},
          });

          // If to capture canceled, status is not equal to Edit in Progress
          if (editStatus.data.status !== "Edit in Progress") {
            data.status = "Edit Delivery"
            this.$bvToast.toast(
              "Edit in Progress failed, please refresh display",
              {
                title: "DO status not synced",
                variant: "danger",
                solid: true,
              }
            );
            this.isLoading = false
            return;
          }
          this.isLoading = false
          this.$bvToast.toast("Change DO Status to Edit in Progress", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        } catch (err) {
          this.$bvToast.toast("Error updating DO, call administrator", {
            title: "Edit to Scanning Failed",
            variant: "danger",
            solid: true,
          });
          this.isLoading = false
          return;
        }
      }
      let idDO = data._id;
      this.isLoading = false
      this.$router.push({
        name: "warehouse-unpick-unscan",
        params: { id: idDO, from: "do-daikin",status:this.deliveryStatus },
      });
    },
    bulkUnscan() {
      this.modalBulk = false;
      var data = this.selectData;
      this.testBulkUnscan({ idDelivery: data._id, deliveryData: data })
        .then(() => {
          this.queryDelivery()

          this.$bvToast.toast("Bulk Unscan Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((e) => {
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    progressValue(status) {
      if (status === 'New') {
        return 0;
      } else if (status === 'Edit Delivery ' || status === 'Edit in Progress' || status === 'Cancel Delivery' || status === 'Delivery Canceled' || status === 'Scanning in Progress' || status === 'Scanning Completed') {
        return 33;
      } else if (status === 'Delivery in Progress') {
        return 66;
      } else if (status === 'Fulfilled' || status === 'Collected' || status === 'Returned' || status === 'Waiting Acknowledgment' || status === 'Waiting approval' || status === 'Approved' || status === 'Return Completed') {
        return 100;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
